h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dd,
dl,
dt,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
}

ul {
    list-style: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
    content: "";
}

html {
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    overflow-x: hidden;
}

a:focus {
    outline: none;
}

a:hover {
    text-decoration: underline;
}

a:hover,
a:active {
    outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}


img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    margin: 0;
}

a {
    cursor: pointer;
    text-decoration: none;
}

strong {
    font-weight: bold;
}

ol,
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

html,
body {
    background: #ffffff;
    color: #2a394e;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

html.open,
body.open {
    overflow: hidden;
}

:target:before {
    content: "";
    display: block;
    height: 100px;
    margin: -100px 0 0;
}

:target a.anchor {
    background: #DFC4FF !important;
    opacity: 1 !important;
    top: 100px !important;
}

.footer {
    align-items: center;
    display: flex;
    margin: 0px auto;
    max-width: 950px;
    padding: 100px 0;
    width: 80vw;
}

.footer > * {
    gap: 10px;
    margin: auto;
    vertical-align: bottom;
    width: 100%;
}

img#icon {
    padding-right: 10px;
    width: 20px;
}

a#social {
    align-items: center;
    color: inherit;
    display: inline-flex;
    float: right;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    height: 13.5px;
    letter-spacing: 1px;
    line-height: 13.5px !important;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-top: 10px;
    text-align: end;
    text-decoration: none;
    text-transform: uppercase;
}

header {
    float: left;
    width: 100%;
}

header .block {
    display: flex;
    justify-content: space-between;
    margin: 120px auto 0px;
    width: 60em;
}

header a.home {
    background: #2a394e;
    height: 80px;
    -webkit-mask: url(assets/logo.svg) center no-repeat;
    mask: url(assets/logo.svg) center no-repeat;
    -webkit-mask-size: 100% auto;
    mask-size: 100% auto;
    overflow: hidden;
    text-indent: -1000px;
    transition: background 0.2s ease;
    width: 100px;
}

header a.home:hover {
    background: #DFC4FF;
}

.text {
    float: left;
    margin: 80px 0 50px;
    width: 100%;
}

.text section {
    clear: both;
    float: left;
    margin: 0;
    width: 100%;
}

.text section h1,
.text section h2,
.text section h3,
.text section h4,
.text section h5,
.text section ol,
.text section p {
    margin: 0 auto;
    width: 800px;
}

.text section figure,
.text section .columns {
    margin: 0px auto;
    width: 55em;
}

.text section h1 {
    box-sizing: border-box;
    clear: both;
    color: #14243c;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 50px;
    font-weight: 700;
    padding: 0 0 35px 0;
    text-decoration: underline;
    text-decoration-color: #DFC4FF;
    text-decoration-skip-ink: none !important;
    -webkit-text-decoration-skip: none !important;
    -webkit-text-decoration-skip-ink: none !important;
    text-decoration-thickness: 10px;
}

.text section h1 span {
    position: relative;
    z-index: 10;
}

.text section h1 a {
    color: inherit;
    text-decoration: none;
}

.text section h1 a:hover span::before {
    background: #f6c6ea;
}

.text section h2 {
    color: #14243c;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 700;
    margin-top: 30px;
    padding: 0 0 40px 0;
    position: relative;
    z-index: 1;
}

ul {
    list-style: none;
    padding: 0 0 20px;
}

li {
    color: #2a394e;
    display: flex;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 10px;
}

.calloutImage {
    margin-bottom: 25px !important;
    max-width: 100%;
    padding-top: 25px;
}

ul li:before {
    color: #DFC4FF;
    content: '\2023';
    font-size: 25px;
    font-weight: bold;
    margin: 0 2em 0 0;
}

.row {
    border-collapse: collapse;
    column-gap: 20px;
    display: flex;
    width: 100%;
}

.column {
    flex: 1;
    vertical-align: top;
}

.column4 {
    float: left;
    padding: 10px;
    width: 22%;
}

hr {
    background-color: #B4E7EF;
    border-style: none;
    height: 10px;
    margin-top: 20px;
    width: 10%;
}

.text section h2 a.anchor {
    background: #14243c;
    height: 30px;
    left: -30px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    text-indent: -9000px;
    top: 0;
    width: 40px;
    z-index: -1;
}

.text section h2 a.anchor:hover {
    background: #DFC4FF;
}

.text section h2:hover a.anchor {
    opacity: 1;
}

.text section h3 {
    color: #14243c;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    padding: 0 0 30px 0;
}

.text section h4 {
    color: #14243c;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    padding: 0 0 30px 0;
    text-decoration: underline;
    text-decoration-color: #DFC4FF;
    text-decoration-skip-ink: none !important;
    -webkit-text-decoration-skip: none !important;
    -webkit-text-decoration-skip-ink: none !important;
    text-decoration-thickness: 7px;
    z-index: -1;
}

.text section h5 {
    color: #14243c;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 700;
}

.text section h6 {
    font-size: 13.5px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 0 0 30px 0;
    text-transform: uppercase;
}

.text section h1,
.text section h2,
.text section h3,
.text section h4,
.text section h6 {
    clear: both;
    width: 650px;
}

.text section hr.yellow {
    background: #ffdc00;
}

.text section hr.space-up {
    margin-top: 100px;
}

.text section p,
.text section p,
.text section ol {
    color: #2a394e;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    padding: 0 150px 30px;
    width: 650px;
}

.text section p.lead,
.text section ol.lead {
    color: #14243c;
    font-size: 18px;
    line-height: 35px;
}

.text section p.caption,
.text section ol.caption {
    color: #869dbe;
    font-size: 14px;
}

.text section p a,
.text section ol a {
    color: inherit;
    text-decoration: underline;
}

.text section p a:hover,
.text section ol a:hover {
    color: #DFC4FF;
}

.text section p a.next,
.text section ol a.next {
    float: left;
    line-height: 30px;
    text-decoration: none;
    width: 100%;
}

.text section p a.next span,
.text section ol a.next span {
    color: #2a394e;
    font-size: 22px;
}

.text section p a.next:hover,
.text section ol a.next:hover {
    color: inherit;
}

.text section p a.loud,
.text section ol a.loud {
    color: #14243c;
    font-weight: 500;
    position: relative;
    text-decoration: none;
    z-index: 2;
}

.text section p a.loud::before,
.text section ol a.loud::before {
    background: #ffdc00;
    bottom: 0;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    transition: all 0.2s;
    width: 100%;
    z-index: -1;
}

.text section p a.loud:hover::before,
.text section ol a.loud:hover::before {
    bottom: -2px;
    height: 10px;
}

.text section ol {
    margin-bottom: 0px;
}

.text section h1 .accent,
.text section h2 .accent,
.text section h3 .accent,
.text section h4 .accent,
.text section p .accent {
    color: #8446f6;
}

.text section h4 .accent {
    color: inherit;
    position: relative;
    z-index: 2;
}

.text section h4 .accent::before {
    background: #dab9ee;
    bottom: 0;
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    transform: rotate(-2deg);
    width: 100%;
    z-index: -1;
}

.text section figure {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.text section figure img {
    border-radius: 4px;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;

}

img.border {
    border: 1px solid #DFC4FF;

}

.text section figure.embed .code {
    padding-top: 56.25%;
    position: relative;
}

.text section figure.embed .code iframe {
    border-radius: 4px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.text section figure.extra-wide {
    max-width: 1600px;
    width: 90%;
}

.text section figure.lowkey-wide {
    max-width: 1600px;
    min-width: 800px;
    width: 60%;
}

.text section .space-up-s {
    margin-top: 25px;
}

.text section .space-up-m {
    margin-top: 50px;
}

.text section .space-up-l {
    margin-top: 100px;
}

.topCorner {
    position: fixed;
    right: 0;
    top: 0;
}

.topCorner > a {
    color: inherit;
    display: block;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    height: 13.5px;
    letter-spacing: 1px;
    line-height: 13.5px !important;
    padding: 10px;
    text-align: end;
    text-decoration: none;

    text-transform: uppercase;
}

.topCorner > a:hover {
    color: #DFC4FF;
}

.prototype {
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 0;
    padding-bottom: 20px;
    width: 55em;
}

@media (max-width: 1400px) {
    .topCorner {
        display: none;
    }
}

@media (max-width: 990px) {
    .prototype {
        flex-direction: column;
        width: 80vw;
    }

    .protoText {
        padding: 0 !important;
        width: 100% !important;
    }

    .protoImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100% !important;
    }
}

.protoImage {
    display: inline-block;
    float: top;
    max-width: 45%;
    object-fit: contain;
}

.protoText {
    display: inline-block;
    min-width: 45%;
    padding: 30px;
}

.callout.inline {
    padding: 8% !important;
    width: 100% !important;
}

.text section .callout {
    background: #f9f6ff;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 20px auto 20px;
    padding: 60px 90px;
    width: 55em;
}

.text section .callout .content {
    width: 650px;
}

.text section .guidelines {
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 0;
    width: 55em;
}

.text section .callout.guideline {
    background-color: #f9f6ff;
    display: inline-block;
    padding: 30px;
    width: 33%;
}

.text section .callout .content-short {
    display: inline-block;
    margin: 40px 0px 0px;
    width: 300px;
}

.text section .callout h2 {
    font-size: 13.5px;
    letter-spacing: 1px;
    line-height: normal;
    margin: 0 0 25px 0;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
}

.text section .callout p {
    color: #2a394e;
    font-size: 17px;
    line-height: 32px;
    margin: 0;
    padding: 0;
    width: 100%;
}

.text section .callout p.spacer {
    margin-top: 30px;
}

.text section .columns {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}

.text section .columns figure {
    float: none;
    margin: 0 5px;
    max-width: 50%;
}

.text section .columns figure:first-child {
    margin-left: 0;
}

.text section .columns figure:last-child {
    margin-right: 0;
}

.text section .columns figure img {
    margin: 0;
}

.text section .columns.space-down-xs figure {
    margin-bottom: 0;
}

.projectList {
    float: left;
    margin: 40px 0 50px;
    width: 100%;
}

.projectList section {
    margin: 0px auto;
    width: 100%;
}

.projectList section h1 {
    box-sizing: border-box;
    float: none;
    font-display: swap;
    font-family: 'Lora', serif;
    font-size: 34px;
    line-height: 50px;
    margin: 0px auto;
    padding: 20px 100px 60px;
    width: 850px;
}

.projectList section h1 a {
    color: inherit;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    z-index: 10;
}

.projectList section h1 a::before {
    background: #ffdc00;
    border-radius: 2px;
    bottom: 0px;
    content: '';
    height: 15px;
    left: 0;
    position: absolute;
    transform: rotate(-1deg);
    transition: all 0.2s;
    width: 100%;
    z-index: -1;
}

.projectList section h1 a:hover::before {
    bottom: 0px;
    height: 100%;
}

.projectList section h2 {
    box-sizing: border-box;
    color: #2a394e;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 0px auto;
    padding: 20px 100px 20px;
    text-transform: uppercase;
    width: 65em;
}

.projectList section ol.projects {
    align-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px auto 0;
    padding: 20px 100px;
    width: 55em;
}

.projectList section ol.projects li,
.projectList section ol.projects a {
    float: left;
    width: 100%;
}

.projectList section ol.projects li {
    width: 50%;
}

.projectList section ol.projects a {
    padding: 30px 0 40px;
    text-decoration: none;
}

.projectList section ol.projects p {
    color: #3c516f;
    font-size: 16px;
    line-height: 28px;
    width: 80%;
}

.projectList .projects {
    margin: 50px auto 0;
    width: 950px;
    height: auto;
}

.projectList .projects .case-study {
    float: left;
    width: 100%;
}

.projectList .projects .case-study a {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
    width: 100%;
}

.projectList .projects .case-study a .thumb {
    box-sizing: border-box;
    min-height: 100%;
}

.projectList .projects .case-study a .thumb .img {
    border-radius: 6px;
    float: left;
    height: 100%;
    width: 100%;
}

.projectList .projects .case-study a .thumb .img.padded {
    border-radius: 56px;
    box-shadow: inset 0 0 0 50px white;
}

.projectList .projects .case-study a .thumb .img img {
    float: left;
    height: auto;
    margin: 0;
    width: 100%;
}

.projectList .projects .case-study a .description {
    margin-top: -10px;
}

.projectList .projects .case-study a .description p {
    box-sizing: border-box;
    clear: both;
    color: #3c516f;
    float: left;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 28px;
    margin: 0;
    height: 100%;
    width: 100%;
}

.projectList .projects .case-study a .description p.title {
    color: #14243c;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
    padding-right: 100px;
}

.projectList .projects .case-study a .description p.link {
    color: #14243c;
    font-display: swap;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 700;
    margin-top: 10px;
}

.projectList .projects .case-study a .description p.link span {
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    z-index: 10;
}

.projectList .projects .case-study a .description p.link span::before {
    background: #ffdc00;
    bottom: 2px;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    transition: all 0.2s;
    width: 100%;
    z-index: -1;
}

.projectList .projects .case-study.large a {
    height: 45em;
}

.projectList .projects .case-study.large a .thumb {
    height: 45em;
    min-width: 525px;
}

.projectList .projects .case-study.large a .description {
    margin-left: 80px;
}

.projectList .projects .case-study.large a .description p {
    font-size: 18px;
    line-height: 28px;
}

.projectList .projects .case-study.large a .description p.title {
    font-size: 32px;
    line-height: 42px;
}

.projectList .projects .case-study.text-left a {
    flex-direction: row-reverse;
}

.projectList .projects .case-study.text-left.large a .description {
    margin-left: 50px;
    margin-right: 80px;
}

.projectList .projects .case-study.text-left.small a .description {
    margin-left: 50px;
    margin-right: 80px;
}

.projectList .projects .case-study.text-left.large a .description p.title {
    padding: 0;
}

.projectList .projects .case-study.small {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    width: 55em;
}

.projectList .projects .case-study.small a {
    min-height: 395px;
}

.projectList .projects .case-study.small a .thumb {
    min-width: 395px;
}

.projectList .projects .case-study.small a .description {
    margin-left: 100px;
}

.projectList .projects .case-study.space-up {
    margin-top: 40px;
}

.projectList .projects .case-study.space-down {
    margin-bottom: 80px;
}

.projectList .projects .case-study.space-up-small {
    margin-top: 20px;
}

.projectList .projects .case-study:hover .description p.link span::before {
    height: 8px;
}

.text section .callout.tip p code {
    background: #ffe7c2;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 14px;
    padding: 2px 5px;
}

.text section h1.remote {
    clear: both;
    height: 59px;
    margin-bottom: 40px;
}

.text section h1.remote div {
    background: #5700ff;
    float: left;
    height: 59px;
    overflow: hidden;
    text-indent: -9000px;
    width: 45px;
}

.projects .case-study a:hover .description p.title {
    text-decoration: underline;
    -webkit-text-decoration-skip: none !important;
    -webkit-text-decoration-skip-ink: none !important;
    text-decoration-skip-ink: none !important;
    text-decoration-thickness: 10px;
}


.projects .case-study a:hover.purple .description p.title {
    text-decoration-color: #DFC4FF;
}

.projects .case-study a:hover.yellow .description p.title {
    text-decoration-color: #fcff9c;

}

.projects .case-study a:hover.blue .description p.title {
    text-decoration-color: #c2ecf2;

}

.projects .case-study a:hover.pink .description p.title {
    text-decoration-color: #FFADCF;

}

.projects .case-study a:hover.green .description p.title {
    text-decoration-color: #00A04B;

}

.tags {
    display: flex;
    gap: 15px;
    padding-top: 20px;
}

#tag {
    background-color: #9c9c9c;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: block;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    height: 13.5px;
    letter-spacing: 1px;
    line-height: 13.5px !important;
    padding: 7px;
    text-align: end;
    text-decoration: none;

    text-transform: uppercase;
}

@media (max-width: 990px) {
    .text section .guidelines {
        flex-direction: column;
        width: 80vw;
    }

    .text section .callout.guideline {
        margin-bottom: 7px;
        width: 100% !important;
    }
}

@media only screen and (max-width: 980px) {
    header .block {
        max-width: 80% !important;
        min-width: 80% !important;
        width: 80%;
    }

    footer {
        max-width: 80% !important;
        min-width: 80% !important;
        width: 80%;
    }

    footer .block {
        margin-bottom: 50px;
        margin-top: 50px;
    }

    footer ol {
        margin-bottom: 20px;
        width: 50%;
    }

    footer ol:last-child,
    footer ol.large {
        width: 100%;
    }

    footer ol.large {
        margin-bottom: 0;
    }

    footer ol.large p {
        display: none;
    }

    .projectList section {
        float: none;
        margin: 0 auto;
        max-width: 80% !important;
        min-width: 80% !important;
        width: 80%;
    }

    .projectList section h1,
    .projectList section h2,
    .projectList section ol.projects {
        min-width: auto;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        height: auto;
    }

    .projectList section .projects {
        width: 100%;
    }

    .projectList section .projects .case-study {
        margin: 0 0 50px 0;
    }

    .projectList section .projects .case-study.space-up {
        margin-top: 0;
    }

    .projectList section .projects .case-study.large a {
        height: auto;
    }

    .projectList section .projects .case-study.large a .thumb {
        height: auto;
        min-width: 50%;
    }

    .projectList section .projects .case-study.large a .description {
        margin-left: 50px;
    }

    .projectList section .projects .case-study.small {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        width: 100%;
    }

    .projectList section .projects .case-study.small a {
        min-height: auto;
    }

    .projectList section .projects .case-study.small a .description {
        margin-left: 50px;
    }

    .projectList section .projects .case-study.small a .thumb {
        height: auto;
        min-width: 50%;
    }

    .text section {
        float: none;
        margin: 0px auto;
        max-width: 80% !important;
        min-width: 80% !important;
        width: 80%;
    }

    .text section h1,
    .text section h2,
    .text section h3,
    .text section h4,
    .text section h5,
    .text section h6,
    .text section p,
    .text section hr,
    .text section ol {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .text section p:last-child {
        clear: both;
        display: inline-block;
    }

    .text section figure,
    .text section figure.extra-wide,
    .text section figure.lowkey-wide,
    .text section .columns,
    .text section .columns figure {
        min-width: auto;
        width: 100%;
    }

    .text section figure {
        margin-bottom: 40px;
    }

    .text section .columns {
        display: block;
    }

    .text section .columns figure {
        margin: 0;
        min-width: 100%;
        width: 100%;
    }

    .text section .columns figure:first-child {
        margin-bottom: 20px;
    }

    .text section .callout {
        margin-left: 0%;
        margin-right: 0;
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
    }

    .text section .callout .content {
        width: 100%;
    }
}

@media only screen and (min-width: 145em) {
    .projectList section ol.tiles li {
        float: none;
        height: 568px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        width: 1010px;
    }
}

@media only screen and (max-width: 600px) {
    html,
    body {
        overflow-x: clip;
    }

    header .block {
        margin-top: 60px;
    }

    .projectList section h1 {
        font-size: 24px;
        line-height: 40px;
    }

    .projectList section h2 {
        padding-top: 40px;
    }

    .projectList section ol {
        margin-top: 40px;
    }

    .projectList section ol.projects li {
        width: 100%;
    }

    .projectList section ol.projects li a {
        padding: 25px 0;
    }

    .projectList section ol.projects li a p {
        width: 90%;
    }

    .projectList section .projects .case-study a {
        flex-direction: column !important;
    }

    .projectList section .projects .case-study a .thumb .img.padded {
        border-radius: 6px;
        box-shadow: none;
    }

    .projectList section .projects .case-study a .description {
        margin: 30px 0 0 0 !important;
    }

    .projectList section .projects .case-study a .description p {
        font-size: 16px !important;
        line-height: 28px !important;
    }

    .projectList section .projects .case-study.space-down {
        margin-bottom: 40px;
    }

    .projectList,
    .text {
        margin-top: 60px;
    }

    .column {
        width: 100%;
    }
}
